const canvasGrid = document.getElementById('gridCanvas');
const ctxGrid = canvasGrid.getContext('2d');

let pointGridRadius = 1.5; // Radius of the point
let pointGridSpacing; // Spacing between points

function resizeGridCanvas() {
	canvasGrid.width = window.innerWidth;
	canvasGrid.height = window.innerHeight;
	pointGridSpacing = canvasGrid.width * 0.045; // 9% for small screens, 4.5% for larger screens

	if ( window.matchMedia( '(min-width: 768px)' ).matches ) {
		pointGridRadius = 1.5;
		pointGridSpacing = canvasGrid.width * 0.045;
	} else {
		pointGridRadius = 1;
		pointGridSpacing = canvasGrid.width * 0.09;
	}

	drawGrid();
}

function drawGridPoint(x, y, size) {
	ctxGrid.beginPath();
	ctxGrid.arc(x, y, size, 0, Math.PI * 2);
	ctxGrid.fill();
}

function drawGrid() {
	ctxGrid.clearRect(0, 0, canvasGrid.width, canvasGrid.height);
	ctxGrid.fillStyle = 'white';

	const cols = Math.ceil(canvasGrid.width / pointGridSpacing);
	const rows = Math.ceil(canvasGrid.height / pointGridSpacing);

	const startX = (canvasGrid.width - (cols - 1) * pointGridSpacing) / 2;
	const startY = (canvasGrid.height - (rows - 1) * pointGridSpacing) / 2;

	for (let i = 0; i < cols; i++) {
		for (let j = 0; j < rows; j++) {
			const x = startX + i * pointGridSpacing;
			const y = startY + j * pointGridSpacing;
			drawGridPoint(x, y, pointGridRadius);
		}
	}
}

window.addEventListener('resize', resizeGridCanvas);
resizeGridCanvas();
